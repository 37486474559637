<div mat-dialog-title class="flex flex-wrap justify-between gap-x-4 gap-y-3">
    <h1 class="max-w-[80%] text-xl md:text-2xl">
        Agregar Características
        {{
            data?.[payloadOptionKeys.quoteProduct]?.productName ??
                data?.[payloadOptionKeys.creationQuote]?.productName ??
                data?.product?.name
        }}
    </h1>

    <mat-icon (click)="dialogRef.close()" class="cursor-pointer"
        >close</mat-icon
    >
</div>

<ng-container *ngIf="loadingStatus.features == loadingStatusEnum.LOADING">
    <div class="flex flex-col items-center justify-center gap-y-4">
        <mat-spinner diameter="50"></mat-spinner>
        <h1 class="w-full text-center text-3xl font-bold">
            Cargando configuraciones...
        </h1>
    </div>
</ng-container>

<ng-container *ngIf="loadingStatus.features == loadingStatusEnum.ERROR">
    <div class="flex w-full flex-col items-center justify-center gap-y-4 p-5">
        <h1 class="w-full bg-red-400 text-center text-3xl font-bold">
            Error al cargar configuraciones
        </h1>

        <button mat-raised-button color="primary" (click)="retryLoadFeatures()">
            Reintentar
        </button>
    </div>
</ng-container>

<ng-container *ngIf="loadingStatus.features == loadingStatusEnum.LOADED">
    <div class="flex w-full items-center justify-between gap-4">
        <div class="flex w-full items-center gap-4">
            <mat-form-field class="w-full max-w-[300px] grow sm:grow-0">
                <mat-label>Configuración</mat-label>
                <input
                    matInput
                    [matAutocomplete]="autoCompleteSettings"
                    [formControl]="form.controls.title"
                />
                <mat-autocomplete
                    (optionSelected)="selectConfiguration($event)"
                    #autoCompleteSettings="matAutocomplete"
                >
                    <mat-option
                        *ngFor="
                            let setting of settingsAutoComplete.dataFiltered
                        "
                        [value]="setting.title"
                    >
                        {{ setting.title }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="assignedConfigurationEqualsActualConfiguration">
                Asignada
                <mat-icon class="text-green-500">check_circle</mat-icon>
            </div>
        </div>

        <div class="flex items-center gap-4">
            <button
                class="min-w-[40%] max-w-full grow sm:min-w-fit sm:grow-0"
                mat-stroked-button
                [color]="'primary'"
                (click)="downloadExcelTemplate()"
            >
                <mat-icon class="mr-2"> download </mat-icon>
                <span class=""> Descargar Plantilla </span>
            </button>
            <button
                class="min-w-[40%] max-w-full grow sm:min-w-fit sm:grow-0"
                mat-flat-button
                [color]="'primary'"
                (click)="openImportExcelModal()"
            >
                <mat-icon class="mr-2">upload_file</mat-icon>
                <span class="">Importar Excel</span>
            </button>
            <button
                *ngIf="canCreateSettings"
                [disabled]="selectedConfiguration || !canCreateSettings"
                mat-raised-button
                color="primary"
                class="ml-auto grow sm:grow-0"
                (click)="addEmptyCharacteristic()"
            >
                Agregar Característica
            </button>
        </div>
    </div>
    <div class="h-full" mat-dialog-content>
        <div class="flex flex-col gap-x-4 gap-y-6 pt-2">
            <ng-container
                *ngFor="
                    let systemForm of form.controls.systems.controls;
                    let systemIndex = index
                "
            >
                <div class="max-h-[400px] overflow-auto">
                    <div
                        class="relative flex flex-wrap place-content-center gap-x-2 rounded-md border-2"
                    >
                        <div
                            class="sticky top-0 z-50 flex w-full flex-wrap items-center justify-end px-3 pt-2 text-center"
                        >
                            <mat-form-field
                                class="mr-auto w-full max-w-[300px]"
                            >
                                <input
                                    [matTooltip]="
                                        !!selectedConfiguration
                                            ? 'No puedes editar una configuración ya creada'
                                            : ''
                                    "
                                    [readonly]="!!selectedConfiguration"
                                    matInput
                                    [formControl]="systemForm.controls.name"
                                    placeholder="Nombre de la característica"
                                />
                            </mat-form-field>

                            <button
                                *ngIf="canCreateSettings"
                                class="mb-[12px] flex grow justify-center gap-x-4 text-center sm:grow-0"
                                mat-stroked-button
                                [disabled]="
                                    selectedConfiguration || !canCreateSettings
                                "
                                color="primary"
                                (click)="addEmptySpecification(systemForm)"
                            >
                                Agregar especificación

                                <mat-icon>add</mat-icon>
                            </button>

                            <button
                                *ngIf="canCreateSettings"
                                [disabled]="
                                    selectedConfiguration || !canCreateSettings
                                "
                                (click)="removeCharacteristic(systemIndex)"
                                mat-icon-button
                                color="warn"
                                class="mb-[12px] flex w-[40px] shrink-0 items-center"
                            >
                                <mat-icon class="cursor-pointer"
                                    >delete</mat-icon
                                >
                            </button>
                        </div>

                        <mat-expansion-panel class="w-full border-none">
                            <mat-expansion-panel-header>
                                <mat-panel-description
                                    [matTooltip]="
                                        'Despliega para ver las especificaciones de la característica ' +
                                        systemForm.controls.name.value
                                            ? systemForm.controls.name.value
                                            : ''
                                    "
                                    class="text-xl font-bold"
                                >
                                    <div
                                        class="flex w-full items-center justify-between"
                                    >
                                        <span>
                                            Especificaciones tecnicas (
                                            {{
                                                systemForm.controls
                                                    .systemFeatures.controls
                                                    .length
                                            }}
                                            )
                                        </span>

                                        <mat-icon>expand_more</mat-icon>
                                    </div>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="grid w-full md:grid-cols-2">
                                <ng-container
                                    *ngIf="
                                        systemForm.controls.systemFeatures
                                            .controls.length > 0
                                    "
                                >
                                    <div
                                        *ngFor="
                                            let systemFeatureForm of systemForm
                                                .controls.systemFeatures
                                                .controls;
                                            let specificationIndex = index
                                        "
                                    >
                                        <div
                                            class="flex w-full flex-wrap items-center justify-center gap-x-1"
                                        >
                                            <mat-form-field
                                                class="field-no-margin grow md:w-[29%]"
                                            >
                                                <input
                                                    [matTooltip]="
                                                        !!selectedConfiguration
                                                            ? 'No puedes editar una configuración ya creada'
                                                            : ''
                                                    "
                                                    [readonly]="
                                                        !!selectedConfiguration ||
                                                        !canCreateSettings
                                                    "
                                                    class="h-full grow px-2 py-2"
                                                    matInput
                                                    [formControl]="
                                                        systemFeatureForm
                                                            .controls.name
                                                    "
                                                    placeholder="Nombre de la especificación"
                                                />
                                            </mat-form-field>

                                            <mat-form-field
                                                class="field-no-margin grow md:w-[29%]"
                                            >
                                                <input
                                                    [matTooltip]="
                                                        !!selectedConfiguration
                                                            ? 'No puedes editar una configuración ya creada'
                                                            : ''
                                                    "
                                                    [readonly]="
                                                        !!selectedConfiguration ||
                                                        !canCreateSettings
                                                    "
                                                    class="h-full grow px-2 py-2"
                                                    matInput
                                                    [formControl]="
                                                        systemFeatureForm
                                                            .controls
                                                            .featureValue
                                                    "
                                                    placeholder="Valor"
                                                />
                                            </mat-form-field>
                                            <button
                                                *ngIf="canCreateSettings"
                                                [disabled]="
                                                    selectedConfiguration ||
                                                    !canCreateSettings
                                                "
                                                (click)="
                                                    removeSpecification(
                                                        systemIndex,
                                                        specificationIndex
                                                    )
                                                "
                                                mat-icon-button
                                                color="warn"
                                                class="mb-[12px] flex w-[40px] shrink-0 items-center"
                                            >
                                                <mat-icon class="cursor-pointer"
                                                    >delete</mat-icon
                                                >
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>

                                <div
                                    class="col-span-full flex w-full items-center justify-center p-5"
                                    *ngIf="
                                        systemForm.controls.systemFeatures
                                            .controls.length == 0
                                    "
                                >
                                    <h1
                                        class="w-full text-center text-xl font-bold"
                                    >
                                        No hay especificaciones
                                    </h1>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div mat-dialog-actions class="flex justify-end gap-x-3">
        <button mat-button (click)="dialogRef.close()">Cancelar</button>
        <button
            *ngIf="data?.[payloadOptionKeys.quoteProduct] && canCreateSettings"
            [matTooltip]="
                assignedConfigurationEqualsActualConfiguration
                    ? 'No puedes asignar una configuración ya asignada'
                    : ''
            "
            [disabled]="
                assignedConfigurationEqualsActualConfiguration ||
                !form.valid ||
                !form.controls.systems.controls.length ||
                !form.controls.systems.valid
            "
            mat-raised-button
            color="primary"
            (click)="createOrAssignSetting()"
        >
            {{ selectedConfiguration ? 'Asignar' : 'Crear' }}
        </button>

        <button
            *ngIf="data?.[payloadOptionKeys.creationQuote] && canCreateSettings"
            [disabled]="
                !form.valid ||
                !form.controls.systems.controls.length ||
                !form.controls.systems.valid ||
                !canCreateSettings
            "
            mat-raised-button
            color="primary"
            (click)="createOrAssignSetting()"
        >
            {{ selectedConfiguration ? 'Asignar' : 'Crear' }}
        </button>

        <button
            *ngIf="
                (data?.[payloadOptionKeys.creationQuote] ||
                    data?.[payloadOptionKeys.quoteProduct]) &&
                !canCreateSettings
            "
            [disabled]="
                !form.valid ||
                !form.controls.systems.controls.length ||
                !form.controls.systems.valid ||
                !selectedConfiguration
            "
            mat-raised-button
            color="primary"
            (click)="createOrAssignSetting()"
        >
            Asignar
        </button>

        <button
            [matTooltip]="
                !!selectedConfiguration
                    ? 'No puedes crear una configuración ya asignada'
                    : ''
            "
            [disabled]="
                !form.valid ||
                !form.controls.systems.controls.length ||
                !form.controls.systems.valid ||
                selectedConfiguration ||
                !canCreateSettings
            "
            mat-raised-button
            color="primary"
            *ngIf="data?.[payloadOptionKeys.product]"
            (click)="createOrAssignSetting()"
        >
            Crear
        </button>
    </div>
</ng-container>
