import {
    CreateProductConfigurationPayload,
    ProductConfigurationDto,
} from '#interfacesv2/api/product-configuration/product-configuration.interface';
import { ApiResponseData } from '#interfacesv2/api/response/http-responses.interface';
import { HeadersHttpService } from '#utils/http-headers/http-headers.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, catchError, map, of, switchMap, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProductConfigurationService {
    baseUrl = `${environment.baseUrl}ProductConfigurations`;

    constructor(
        private httpClient: HttpClient,
        private httpHeadersService: HeadersHttpService
    ) {}

    Create(payload: CreateProductConfigurationPayload) {
        const response = this.httpClient
            .post(`${this.baseUrl}/Create/${payload.productId}`, payload, {
                headers: this.httpHeadersService.getHeadersPrivate(),
            })
            .pipe(
                map((response: any) => response),
                catchError((error) => {
                    return throwError(() => error as HttpErrorResponse);
                })
            );

        return response;
    }

    GetAllByProductId(
        productId: number
    ): Observable<ApiResponseData<ProductConfigurationDto[]>> {
        const response = this.httpClient
            .get(`${this.baseUrl}/GetAllByProductId/${productId}`, {
                headers: this.httpHeadersService.getHeadersPrivate(),
            })
            .pipe(
                map((response: any) => response),
                catchError((error) => {
                    return throwError(() => error as HttpErrorResponse);
                })
            );

        return response;
    }

    CreateByTemplate(
        id: number,
        configurationTitle: string,
        file: FormData
    ): Observable<ApiResponseData<any>> {
        return this.httpClient
            .post<ApiResponseData<any>>(
                `${this.baseUrl}/CreateByTemplate/${id}/${configurationTitle}`,
                file,
                {
                    headers:
                        this.httpHeadersService.getHeadersFormDataPrivate(),
                }
            )
            .pipe(
                switchMap((value) => {
                    return of(value);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
    }
}
