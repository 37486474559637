import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PurchasedProductDto } from '#interfacesv2/api/opportunity/purchased-quote-product.interface';
import {
    AssignConfigurationPayload,
    QuoteProductDto,
    QuoteProductFeaturesDto,
} from '#interfacesv2/api/opportunity/quote-product.interface';
import {
    ApiPaginationOptions,
    ApiResponse,
} from '#interfacesv2/api/response/api-response-base.interface';
import {
    ApiResponseData,
    ProductDetailResponse,
    QuoteProductDetailResponse,
    QuoteProductsResponse,
} from '#interfacesv2/api/response/http-responses.interface';
import { HeadersHttpService } from '#utils/http-headers/http-headers.service';
import { environment } from 'environments/environment';
import {
    BehaviorSubject,
    Observable,
    catchError,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuoteProductService {
    private _quoteProducts: BehaviorSubject<QuoteProductsResponse | null> =
        new BehaviorSubject(null);

    private baseUrl: string = environment.baseUrl + 'QuoteProduct/';

    constructor(
        private httpClient: HttpClient,
        private httpHeaders: HeadersHttpService
    ) {}

    get opinions$(): Observable<QuoteProductsResponse> {
        return this._quoteProducts.asObservable();
    }

    GetAll(
        page: number,
        size: number,
        name: string = ' ',
        sort: string = '',
        column: string = ''
    ): Observable<QuoteProductsResponse> {
        return this.httpClient
            .get<QuoteProductsResponse>(this.baseUrl + 'GetAll', {
                headers: this.httpHeaders.getHeadersPrivate(),
                params: {
                    pageNumber: page,
                    pageSize: size,
                    Parameter: name,
                    Order: sort,
                    Column: column,
                },
            })
            .pipe(
                tap((res) => {
                    this._quoteProducts.next(res);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
    }

    Create(
        quoteProducts: Array<{
            itemCode: string;
            productId: number;
            quantity: number;
            salePrice: number;
            discountPercentage: number;
            productName: string;
            warehouseId: number;
            productSpecialPriceId: number;
            totalBase: number;
            taxCode: string;
            saleChannelId: number;
        }>,
        payload: { opportunityId: number; userId: string; customerId: string },
        showModal = true
    ): Observable<QuoteProductsResponse> {
        const response = this.httpClient
            .post<QuoteProductsResponse>(
                this.baseUrl + 'Create',
                { QuoteProducts: quoteProducts, ...payload },
                { headers: this.httpHeaders.getHeadersPrivate({ showModal }) }
            )
            .pipe(
                switchMap((resp) => {
                    return of(resp);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
        return response;
    }

    Update(
        quoteProduct: QuoteProductDto
    ): Observable<QuoteProductDetailResponse> {
        const response = this.httpClient
            .put<QuoteProductDetailResponse>(
                this.baseUrl + 'Update/' + quoteProduct.id,
                quoteProduct,
                { headers: this.httpHeaders.getHeadersPrivate() }
            )
            .pipe(
                switchMap((resp) => {
                    return of(resp);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
        return response;
    }

    BulkUpdate(
        quoteProducts: (QuoteProductDto & { itemCode: string })[],
        opportunityId: number,
        showModal = true
    ): Observable<QuoteProductDetailResponse> {
        const response = this.httpClient
            .put<QuoteProductDetailResponse>(
                this.baseUrl + 'BulkUpdate',
                { quoteProductList: quoteProducts, opportunityId },
                { headers: this.httpHeaders.getHeadersPrivate({ showModal }) }
            )
            .pipe(
                tap((resp) => resp),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
        return response;
    }

    Delete(quoteProduct: QuoteProductDto) {
        const response = this.httpClient
            .delete<ApiResponse>(this.baseUrl + 'Delete/' + quoteProduct.id, {
                headers: this.httpHeaders.getHeadersPrivate(),
            })
            .pipe(
                switchMap((resp) => {
                    return of(resp);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
        return response;
    }

    UpdateStatus(id: number, showModal: boolean = true) {
        const response = this.httpClient
            .put<QuoteProductDetailResponse>(
                this.baseUrl + 'UpdateStatus/' + id,
                { Id: id },
                { headers: this.httpHeaders.getHeadersPrivate({ showModal }) }
            )
            .pipe(
                switchMap((resp) => {
                    return of(resp);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
        return response;
    }

    UpdateGenericProduct(productId: number, quoteProductId: number) {
        const response = this.httpClient
            .put<ApiResponse>(
                this.baseUrl + 'UpdateGenericProduct',
                { productId, quoteProductId },
                { headers: this.httpHeaders.getHeadersPrivate() }
            )
            .pipe(
                switchMap((resp) => {
                    return of(resp);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
        return response;
    }

    ApplyExemptQuoteProduct(
        opportunityId: number,
        isActive: boolean,
        showModal = true
    ): Observable<ProductDetailResponse> {
        return this.httpClient
            .put<ProductDetailResponse>(
                `${this.baseUrl}ApplyExemptQuoteProduct`,
                { opportunityId, applyExempt: isActive },
                { headers: this.httpHeaders.getHeadersPrivate({ showModal }) }
            )
            .pipe(
                switchMap((createdProduct) => {
                    return of(createdProduct);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
    }

    UpdateQuoteProductsPrices(
        opportunityId: number,
        quoteProductIdList?: number[]
    ): Observable<ApiResponseData<QuoteProductDto[]>> {
        return this.httpClient
            .put<
                ApiResponseData<QuoteProductDto[]>
            >(this.baseUrl + 'UpdateQuoteProductsPrices', { opportunityId, quoteProductIdList }, { headers: this.httpHeaders.getHeadersPrivate() })
            .pipe(
                switchMap((createProduct) => {
                    return of(createProduct);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
    }

    AddToGroup(payload: {
        opportunityId: number;
        groupName: string;
        groupCode: string;
        quoteProductIds: number[];
    }) {
        return this.httpClient
            .put<
                ApiResponseData<QuoteProductDto[]>
            >(this.baseUrl + 'AddToGroup', payload, { headers: this.httpHeaders.getHeadersPrivate() })
            .pipe(
                switchMap((createProduct) => {
                    return of(createProduct);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
    }

    RemoveFromGroup(payload: {
        opportunityId: number;
        quoteProductIds: number[];
    }) {
        return this.httpClient
            .put<
                ApiResponseData<QuoteProductDto[]>
            >(this.baseUrl + 'RemoveFromGroup', payload, { headers: this.httpHeaders.getHeadersPrivate() })
            .pipe(
                switchMap((createProduct) => {
                    return of(createProduct);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
    }

    GetFeaturesById(
        quoteProductId: number
    ): Observable<ApiResponseData<QuoteProductFeaturesDto>> {
        const response = this.httpClient
            .get<ApiResponseData<QuoteProductFeaturesDto>>(
                this.baseUrl + 'GetFeaturesById/' + quoteProductId,
                {
                    headers: this.httpHeaders.getHeadersPrivate(),
                }
            )

            .pipe(
                switchMap((res) => {
                    return of(res);
                }),

                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );

        return response;
    }

    AssignConfiguration(payload: AssignConfigurationPayload) {
        const response = this.httpClient
            .post<ApiResponseData<QuoteProductDto>>(
                `${environment.baseUrl}QuoteProduct/Assign/${payload.quoteProductId}/${payload.productConfigurationId}`,
                {
                    productConfigurationId: payload.productConfigurationId,

                    quoteProductId: payload.quoteProductId,
                },
                { headers: this.httpHeaders.getHeadersPrivate() }
            )
            .pipe(
                switchMap((resp) => {
                    return of(resp);
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );

        return response;
    }

    GetAllPurchased(
        payload: ApiPaginationOptions & {
            customerId: string;
            opportunitySteps: number[];
        }
    ) {
        return this.httpClient
            .get<ApiResponseData<PurchasedProductDto[]>>(
                this.baseUrl + 'GetAllPurchased',
                {
                    headers: this.httpHeaders.getHeadersPrivate(),
                    params: {
                        ...payload,
                    },
                }
            )
            .pipe(
                tap((res) => {
                    return res;
                }),
                catchError((err) => {
                    return throwError(() => err as HttpErrorResponse);
                })
            );
    }
}
