import { BasicDetailProductDto } from '#interfacesv2/api/product/basic-detail-product.interface';
import { ProductDto } from '#interfacesv2/api/product/product.interface';
import { ProductConfigurationService } from '#servicesv2/api/product-configuration/product-configuration.service';
import { AlertService } from '#utils/alert-service/alert-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

export interface ImportConfigurationExcelComponentData {
    product?: ProductDto | BasicDetailProductDto;
    productId?: number;
}

@Component({
    selector: 'app-import-configuration-excel-modal',
    templateUrl: './import-excel-modal.component.html',
})
export class ImportConfigurationExcelComponent implements OnInit {
    form = new FormGroup({
        configurationName: new FormControl('', [Validators.required]),
    });
    configurationTitle: string | null = null;
    unsuscribe = new Subject();
    constructor(
        private dialogRef: MatDialogRef<ImportConfigurationExcelComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: ImportConfigurationExcelComponentData,
        private productConfigurationService: ProductConfigurationService,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.form.controls.configurationName.valueChanges.subscribe((value) => {
            this.configurationTitle = value;
        });
    }

    importExcelTemplated(event: any) {
        const formData = new FormData();
        const files = event.target.files;
        const productId = this.data.product
            ? this.data.product.id
            : this.data.productId;
        if (files && productId) {
            const file = files[0];
            formData.append('excelFile', file);
            this.productConfigurationService
                .CreateByTemplate(productId, this.configurationTitle, formData)
                .subscribe({
                    next: (data) => {
                        if (data.succeeded) {
                            this.alertService.saveAlert(data?.message);
                            this.dialogRef.close(data);
                        }
                    },
                    error: (error) => {
                        this.dialogRef.close();
                        this.alertService.errorAlert(
                            error?.error?.Errors ?? error?.error?.errors,
                            error?.error?.Message ?? error?.error?.message
                        );
                    },
                });
        }
    }
}
