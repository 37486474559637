import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ImportConfigurationExcelComponent } from '#root/modules/admin/crm/opportunity/dialogs/quote-add-characteristics/modals/import-excel/import-excel-modal.component';
import { QuoteAddCharacteristicsComponent } from '#root/modules/admin/crm/opportunity/dialogs/quote-add-characteristics/quote-add-characteristics.component';
import { LoadingDialogComponent } from '#root/shared/components/loading-dialog/loading-dialog.componente';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LazyLoadedParentComponent } from './components/lazy-loaded-parent/lazy-loaded-parent.component';
import { MapSearcherComponent } from './components/map-searcher/map-searcher.component';
import { ProductsDialogComponent } from './components/products-dialog/products-dialog.component';
import { VersionChangelogComponent } from './components/version-changelog/version-changelog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatIconModule,
        MatDialogModule,
        MatDividerModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatRadioModule,
        MatChipsModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LazyLoadedParentComponent,
        MapSearcherComponent,
        ImportConfigurationExcelComponent,
        QuoteAddCharacteristicsComponent,
    ],
    declarations: [
        ProductsDialogComponent,
        VersionChangelogComponent,
        LoadingDialogComponent,
        LazyLoadedParentComponent,
        MapSearcherComponent,
        ImportConfigurationExcelComponent,
        QuoteAddCharacteristicsComponent,
    ],
})
export class SharedModule {}
