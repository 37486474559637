import {
    ProductBasicDataSheetDto,
    QuoteProductFeaturesDto,
} from '#interfacesv2/api/opportunity/quote-product.interface';
import {
    CreateProductConfigurationPayload,
    ProductConfigurationDto,
    ProductSystemDto,
    ProductSystemFeatureDto,
} from '#interfacesv2/api/product-configuration/product-configuration.interface';
import { BasicDetailProductDto } from '#interfacesv2/api/product/basic-detail-product.interface';
import { ProductDto } from '#interfacesv2/api/product/product.interface';
import { ApiResponseData } from '#interfacesv2/api/response/http-responses.interface';
import { Roles } from '#interfacesv2/api/user/enum/roles.enum';
import { ProductConfigurationService } from '#servicesv2/api/product-configuration/product-configuration.service';
import { QuoteProductService } from '#servicesv2/api/quote-product/quote-product.service';
import { AlertService } from '#utils/alert-service/alert-service.service';
import { AutocompleteData } from '#utils/autocomplete/autocomplete';
import { AfterViewInit, Component, Inject, type OnInit } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import {
    ImportConfigurationExcelComponent,
    ImportConfigurationExcelComponentData,
} from './modals/import-excel/import-excel-modal.component';

enum PayloadOptionKeys {
    quoteProduct = 'quoteProduct',
    creationQuote = 'creationQuote',
    product = 'product',
    productId = 'productId',
}

export type QuoteCharacteristicsDialogPayload = {
    quoteProduct?: { id: number; productId: number; productName: string };
    creationQuote?: {
        productId: number;
        productConfigurationId: number;
        productName: string;
    };
    product?: ProductDto | BasicDetailProductDto;
    productId?: number;
};

export type SystemFormGroup = FormGroup<{
    id: FormControl<number | null>;
    name: FormControl<string | null>;
    systemFeatures: FormArray<SystemFeatureFormGroup>;
}>;

export type SystemFeatureFormGroup = FormGroup<{
    id: FormControl<number | null>;
    name: FormControl<string | null>;
    featureValue: FormControl<string | null>;
}>;

enum LoadingStatusEnum {
    LOADING = 0,
    LOADED = 1,
    ERROR = 2,
}

const EXCEL_TEMPLATE_URL =
    'https://platinosoftware.blob.core.windows.net/grupo-platino/Plantilla-de-configuraciones.xlsx';

@Component({
    selector: 'app-quote-add-characteristics',

    templateUrl: './quote-add-characteristics.component.html',
    styles: [
        `
            table {
                @apply w-full bg-white text-left rtl:text-right;
            }
            thead {
                @apply bg-[#f8fafc] text-gray-500;
            }
            th {
                @apply py-3 text-md;
            }

            td {
                @apply h-[50px] max-h-[50px] border border-b-0 border-r-0 border-gray-400 outline-1;
            }
        `,
    ],
})
export class QuoteAddCharacteristicsComponent implements OnInit, AfterViewInit {
    constructor(
        public dialogRef: MatDialogRef<QuoteAddCharacteristicsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: QuoteCharacteristicsDialogPayload,
        private quoteService: QuoteProductService,
        private formBuilder: FormBuilder,
        private productConfiguration: ProductConfigurationService,
        private alertService: AlertService,
        private dialogService: MatDialog
    ) {}

    unsuscribe = new Subject();

    rol = localStorage.getItem('rol');

    quoteProductFeatures: QuoteProductFeaturesDto = null;
    assignedConfiguration: ProductConfigurationDto = null;
    dataSheets: ProductBasicDataSheetDto[] = [];
    configurations: ProductConfigurationDto[] = [];

    loadingStatusEnum = LoadingStatusEnum;

    loadingStatus = {
        features: this.loadingStatusEnum.LOADING,
    };

    payloadOptionKeys = PayloadOptionKeys;

    settingsAutoComplete = new AutocompleteData<ProductConfigurationDto>({
        displayWithKey: 'title',
        filterByKey: 'title',
        displayWithShowKey: 'title',
        autoCompleteName: 'settings-quote-modal',
    });

    createNewSystemFormGroup(system?: ProductSystemDto): SystemFormGroup {
        const systemForm: SystemFormGroup = this.formBuilder.group({
            id: this.formBuilder.control(system?.id ?? null),
            name: this.formBuilder.control(system?.name ?? null, [
                Validators.required,
            ]),
            systemFeatures: new FormArray<SystemFeatureFormGroup>([]),
        });

        if (system?.productSystemFeatures) {
            system?.productSystemFeatures?.forEach((specification) => {
                const specificationForm =
                    this.createNewSystemFeatureFormGroup(specification);
                systemForm.controls.systemFeatures.controls.push(
                    specificationForm
                );
            });
        }

        return systemForm;
    }

    createNewSystemFeatureFormGroup(
        productSystemFeature?: ProductSystemFeatureDto
    ): SystemFeatureFormGroup {
        const systemFormFeatureForm: SystemFeatureFormGroup =
            this.formBuilder.group({
                id: this.formBuilder.control(productSystemFeature?.id ?? null),
                name: this.formBuilder.control(
                    productSystemFeature?.name ?? null,
                    [Validators.required]
                ),
                featureValue: this.formBuilder.control(
                    productSystemFeature?.value ?? null,
                    [Validators.required]
                ),
            });

        return systemFormFeatureForm;
    }

    form = this.formBuilder.group({
        id: this.formBuilder.control(null),
        productId: this.formBuilder.control(null),
        title: this.formBuilder.control(null, [Validators.required]),
        systems: new FormArray<SystemFormGroup>([]),
    });

    ngOnInit(): void {
        if (
            PayloadOptionKeys.creationQuote in this.data &&
            PayloadOptionKeys.quoteProduct in this.data
        ) {
            throw new Error(
                "You can't use both creationQuote and quoteProduct in the same payload"
            );
        }

        if (PayloadOptionKeys.quoteProduct in this.data) {
            this.configurationIdValueChanges();
            this.form.controls.productId.setValue(
                this.data.quoteProduct.productId
            );

            this.loadFeatures();
        }

        if (PayloadOptionKeys.creationQuote in this.data) {
            this.form.controls.productId.setValue(
                this.data.creationQuote.productId
            );

            this.loadCreationFeatures();
        }

        if (PayloadOptionKeys.product in this.data) {
            this.form.controls.productId.setValue(this.data.product.id);

            this.loadForProductScreen();
        }
    }

    ngAfterViewInit(): void {
        if (!this.canCreateSettings) {
            this.form.disable();
            this.form.controls.title.enable();
        }
    }

    addEmptyCharacteristic(): void {
        const characteristicForm = this.createNewSystemFormGroup();
        this.form.controls.systems.push(characteristicForm, {
            emitEvent: false,
        });
    }

    addEmptySpecification(characteristicReference: SystemFormGroup): void {
        const featureForm = this.createNewSystemFeatureFormGroup();

        characteristicReference.controls.systemFeatures.push(featureForm);
    }

    loadFeatures() {
        if (PayloadOptionKeys.quoteProduct in this.data) {
            const quoteId = this.data.quoteProduct.id;

            return new Promise((resolve, reject) => {
                this.quoteService
                    .GetFeaturesById(quoteId)
                    .pipe(takeUntil(this.unsuscribe))
                    .subscribe({
                        next: (res) => {
                            this.quoteProductFeatures = res.data;
                            this.assignedConfiguration =
                                res.data.assignedConfiguration;
                            this.dataSheets = res.data.dataSheets;
                            this.configurations = res.data.configurations;
                            this.settingsAutoComplete.setData(
                                res.data.configurations
                            );

                            if (this.assignedConfiguration) {
                                this.loadSettingToForm(
                                    this.assignedConfiguration
                                );
                            }

                            this.form.controls.id.setValue(
                                this.form.controls.id.value
                            );
                            this.alertService.saveAlert(res?.message);

                            this.loadingStatus.features =
                                this.loadingStatusEnum.LOADED;
                            resolve(null);
                        },

                        error: (err) => {
                            this.alertService.errorAlert(
                                err?.error?.Errors ?? err?.error?.errors,
                                err?.error?.Message ?? err?.error?.message
                            );
                            this.loadingStatus.features =
                                this.loadingStatusEnum.ERROR;
                            resolve(null);
                        },
                    });
            });
        }
    }

    loadCreationFeatures() {
        if (PayloadOptionKeys.creationQuote in this.data) {
            const productId = this.data.creationQuote.productId;

            return new Promise((resolve, reject) => {
                this.productConfiguration
                    .GetAllByProductId(productId)
                    .pipe(takeUntil(this.unsuscribe))
                    .subscribe({
                        next: (res) => {
                            this.configurations = res.data;
                            this.settingsAutoComplete.setData(res.data);

                            this.loadingStatus.features =
                                this.loadingStatusEnum.LOADED;

                            if (
                                this.data.creationQuote.productConfigurationId
                            ) {
                                const foundSetting = this.configurations.find(
                                    (setting) =>
                                        setting.id ==
                                        this.data.creationQuote
                                            .productConfigurationId
                                );
                                if (foundSetting) {
                                    this.loadSettingToForm(foundSetting);
                                }
                                this.assignedConfiguration = foundSetting;
                            }

                            resolve(null);
                        },

                        error: (err) => {
                            this.alertService.errorAlert(
                                err?.error?.Errors ?? err?.error?.errors,
                                err?.error?.Message ?? err?.error?.message
                            );
                            this.loadingStatus.features =
                                this.loadingStatusEnum.ERROR;
                            resolve(null);
                        },
                    });
            });
        }
    }

    loadForProductScreen() {
        if (PayloadOptionKeys.product in this.data) {
            return new Promise((resolve) => {
                this.productConfiguration
                    .GetAllByProductId(this.data.product.id)
                    .pipe(takeUntil(this.unsuscribe))
                    .subscribe({
                        next: (res) => {
                            this.configurations = res.data;
                            this.settingsAutoComplete.setData(res.data);

                            this.loadingStatus.features =
                                this.loadingStatusEnum.LOADED;

                            resolve(null);
                        },

                        error: (err) => {
                            this.alertService.errorAlert(
                                err?.error?.Errors ?? err?.error?.errors,
                                err?.error?.Message ?? err?.error?.message
                            );
                            this.loadingStatus.features =
                                this.loadingStatusEnum.ERROR;
                            resolve(null);
                        },
                    });
            });
        }
    }

    retryLoadFeatures() {
        this.loadingStatus.features = this.loadingStatusEnum.LOADING;
        this.loadFeatures();
    }

    assignNewSetting(settingId: number) {
        if (PayloadOptionKeys.quoteProduct in this.data) {
            const quoteId = this.data.quoteProduct.id;

            return new Promise((resolve) => {
                const payload = {
                    quoteProductId: quoteId,
                    productConfigurationId: settingId
                        ? settingId
                        : this.form.value.id,
                };

                this.quoteService
                    .AssignConfiguration(payload)
                    .pipe(takeUntil(this.unsuscribe))
                    .subscribe({
                        next: (res: any) => {
                            if (res?.data?.assignedConfiguration) {
                                this.assignedConfiguration =
                                    res.data.assignedConfiguration;
                            }

                            this.alertService.saveAlert(res?.message);
                            resolve(null);
                        },

                        error: (err) => {
                            this.alertService.errorAlert(
                                err?.error?.Errors ?? err?.error?.errors,
                                err?.error?.Message ?? err?.error?.message
                            );
                            resolve(null);
                        },
                    });
            });
        }
    }

    async createOrAssignSetting() {
        if (this.data?.product) {
            this.form.controls.productId.setValue(this.data.product.id);
            this.createNewSetting();

            return;
        }

        if (this.selectedConfiguration && this.data?.creationQuote) {
            this.dialogRef.close(this.selectedConfiguration);
            return;
        }

        if (this.selectedConfiguration && this.data?.quoteProduct) {
            return this.assignNewSetting(this.selectedConfiguration?.id);
        } else {
            const createdSetting = await this.createNewSetting();

            this.configurations.push(createdSetting.data);
            if (createdSetting?.data?.id && this.data?.quoteProduct) {
                await this.assignNewSetting(createdSetting.data.id);
                this.loadFeatures();
            } else {
                this.loadSettingToForm(createdSetting.data);
            }
        }
    }

    createNewSetting(): Promise<ApiResponseData<ProductConfigurationDto>> {
        return new Promise((resolve) => {
            const payload: CreateProductConfigurationPayload = {
                productId: this.form.value.productId,
                title: this.form.value.title,
                systems: this.form.controls.systems.controls.map((control) => {
                    return {
                        name: control.value.name,
                        systemFeatures:
                            control.controls.systemFeatures.controls.map(
                                (specificationControl) => {
                                    return {
                                        name: specificationControl.value.name,
                                        value: specificationControl.value
                                            .featureValue,
                                    };
                                }
                            ),
                    };
                }),
            };

            this.productConfiguration
                .Create(payload)
                .pipe(takeUntil(this.unsuscribe))
                .subscribe({
                    next: (res) => {
                        this.alertService.saveAlert(res?.message);
                        resolve(res);
                    },

                    error: (err) => {
                        this.alertService.errorAlert(
                            err?.error?.Errors ?? err?.error?.errors,
                            err?.error?.Message ?? err?.error?.message
                        );
                        resolve(null);
                    },
                });
        });
    }

    removeCharacteristic(characteristicIndex: number): void {
        (this.form.controls.systems as FormArray).removeAt(characteristicIndex);
    }

    removeSpecification(
        characteristicIndex: number,
        specificationIndex: number
    ): void {
        const characteristicReference = (
            this.form.controls.systems as FormArray
        ).at(characteristicIndex) as SystemFormGroup;
        (characteristicReference.controls.systemFeatures as FormArray).removeAt(
            specificationIndex
        );
    }

    addCharacteristics(): void {}

    selectConfiguration(event: MatAutocompleteSelectedEvent): void {
        const foundSetting = this.configurations.find(
            (setting) => setting.title == event.option.value
        );
        if (foundSetting) {
            this.loadSettingToForm(foundSetting);
        }
    }

    loadSettingToForm(setting: ProductConfigurationDto): void {
        this.form.controls.id.setValue(setting.id);
        this.form.controls.title.setValue(setting.title, {
            emitEvent: false,
        });
        this.form.controls.systems = this.formBuilder.array(
            setting.productSystems.map((characteristic) => {
                return this.createNewSystemFormGroup(characteristic);
            })
        );

        this.settingsAutoComplete.dataFiltered = this.configurations;
    }

    configurationIdValueChanges() {
        this.form.controls.title.valueChanges
            .pipe(takeUntil(this.unsuscribe))
            .subscribe({
                next: (value: any) => {
                    const foundSetting = this.configurations.find(
                        (setting) => setting.title == value
                    );

                    this.settingsAutoComplete.filter(value);

                    if (foundSetting) {
                        this.form.controls.id.setValue(foundSetting.id, {
                            emitEvent: false,
                        });
                        this.loadSettingToForm(foundSetting);
                    } else {
                        this.form.controls.id.setValue(null, {
                            emitEvent: false,
                        });
                    }
                },
            });
    }

    openImportExcelModal() {
        this.dialogService
            .open<
                ImportConfigurationExcelComponent,
                ImportConfigurationExcelComponentData
            >(ImportConfigurationExcelComponent, {
                data: {
                    product: this.data.product,
                    productId: this.data.productId,
                },
            })
            .afterClosed()
            .subscribe((value) => {
                if (value.data) {
                    if (PayloadOptionKeys.quoteProduct in this.data) {
                        const settingId = value.data.id;
                        this.assignNewSetting(settingId);
                        this.dialogRef.close();
                    }
                    this.dialogRef.close();
                }
            });
    }

    downloadExcelTemplate() {
        window.open(EXCEL_TEMPLATE_URL, '_blank');
    }

    get selectedConfiguration() {
        return this.configurations.find(
            (setting) => setting.title == this.form.controls.title.value
        );
    }

    get assignedConfigurationEqualsActualConfiguration() {
        const isAssigned =
            this.assignedConfiguration?.id == this.form.controls.id.value &&
            this.assignedConfiguration != null;

        return isAssigned;
    }

    get isWithCreatedQuoteProduct() {
        return PayloadOptionKeys.creationQuote in this.data;
    }

    get canCreateSettings() {
        return (
            this.rol == Roles.Admin ||
            this.rol == Roles.Manager ||
            this.rol == Roles.SuperAdmin
        );
    }
}
