<h1 mat-dialog-title>
    Importar configuraciones de producto
    <h1>
        <p class="text-sm">
            Asistente para importar las distintas configuraciones del producto.
        </p>

        <mat-dialog-content>
            <div [formGroup]="form">
                <mat-form-field class="w-full">
                    <mat-label>Titulo de configuración</mat-label>
                    <input matInput formControlName="configurationName" />
                    <mat-error
                        *ngIf="
                            form.get('configurationName').hasError('required')
                        "
                    >
                        Es requerido ingresar un titulo para la configuración.
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button mat-flat-button color="warn" mat-dialog-close>
                Cancelar
            </button>
            <button
                mat-flat-button
                color="primary"
                (click)="fileInput.click()"
                [disabled]="!configurationTitle"
            >
                Importar
            </button>
            <input
                hidden
                type="file"
                #fileInput
                (change)="importExcelTemplated($event)"
            />
        </mat-dialog-actions>
    </h1>
</h1>
